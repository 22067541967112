<!--
 * @Descripttion: 说明
 * @Author: liuzhixiang
 * @Date: 2021-07-14 11:28:30
 * @LastEditors: liuzhixiang
 * @LastEditTime: 2021-07-14 14:47:42
-->

<template>
  <div class="evaluate" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="发布评价"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 商品信息 -->
    <div class="order_info">
      <div class="info_detail">
        <img :src="detail.SpecImg" alt="" />
        <div class="detail_content">
          <div class="decimalPoint-2">{{ detail.GoodsName }}</div>
          <div>
            <span>{{ detail.SpecName }}</span
            ><span>x{{ detail.GoodsCount }}</span>
          </div>
          <div>
            <span v-if="detail.Points > 0">{{ detail.Points }}积分+</span>
            ￥<span>{{ detail.GoodsPrice }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 评价 -->
    <div class="evaluate_cont">
      <div class="top">
        <div class="rate">
          <span class="sppj">商品评价</span>
          <van-rate v-model="rateValue" />
        </div>
        <span>
          {{
            rateValue == 1
              ? "完全不满意"
              : rateValue == 2
              ? "不满意"
              : rateValue == 3
              ? "一般"
              : rateValue == 4
              ? "满意"
              : rateValue == 5
              ? "非常满意"
              : ""
          }}
        </span>
      </div>
      <van-field
        class="release_text"
        v-model="text"
        rows="5"
        autosize
        type="textarea"
        placeholder="从多个角度评价宝贝，可以帮助更多想买的人"
      />
      <van-uploader
        v-model="fileList"
        :after-read="afterRead"
        multiple
        upload-text="添加图片"
      />
      <van-checkbox v-model="checked" checked-color="#F95E2E"
        ><span class="c_999999">匿名评价</span></van-checkbox
      >
    </div>
    <div>
      <div class="tj_btn" @click="postGoodsEvaluationAdd">提交</div>
    </div>
  </div>
</template>

<script>
import navBar from "../components/navBar.vue";
import { imagesUploadPic } from "@/api/api";
import { Toast } from "vant";
import { queryShopOrderGoodsDetail, goodsEvaluationAdd } from "@/api/shop";
export default {
  data() {
    return {
      value: 1,
      rateValue: 0,
      text: "",
      checked: false,
      detail: {},
      fileList: [],
      imgList: [],
      userInfo: {},
      orderId: 0,
      specId: 0,
      isWxApplets: true,
    };
  },
  components: {
    navBar,
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "发布评价";
      this.isWxApplets = false;
    }
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    this.orderId = this.$route.query.orderId;
    this.specId = this.$route.query.specId;
    this.getQueryShopOrderGoodsDetail(this.orderId, this.specId);
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    //提交
    async postGoodsEvaluationAdd() {
      if (this.rateValue == 0) {
        Toast.fail({
          message: "请对商品进行评分",
        });
        return false;
      }
      let parm = {};
      parm.GoodsId = this.detail.GoodsId;
      parm.StudentId = this.userInfo.Id;
      parm.StudentName = this.userInfo.RealName;
      parm.Score = this.rateValue;
      parm.Evaluation = this.text;
      parm.EvaluationImg = this.imgList.join(",");
      parm.IsAnonymous = this.checked ? 1 : 0;
      parm.ShopOrderId = this.orderId;
      parm.SpecId = this.specId;
      parm.SpecName = this.detail.SpecName;
      const res = await goodsEvaluationAdd(parm);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.$router.back(-1);
        // this.$router.push({
        //   path: "/shop/orders/index",
        // });
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },
    // 订单商品详情
    async getQueryShopOrderGoodsDetail(orderId, specId) {
      const res = await queryShopOrderGoodsDetail(
        "orderId=" + orderId + "&&specId=" + specId
      );
      this.detail = res.response;
      console.log(this.detail);
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      console.log(file.file);
      this.postImagesUploadPic(file.file);
    },
    // 上传文件
    async postImagesUploadPic(file) {
      const formData = new FormData();
      let newFile = await this.common.compressImg(file); //图片压缩
      formData.append("file", newFile);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        this.imgList.push(url);
        console.log(this.imgList);
      } else {
        this.$message.error(res.msg);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  min-height: 100vh;
  background-color: #f5f5f5;
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    margin-bottom: 0.625rem;
    .info_detail {
      display: flex;
      margin-bottom: 0.9375rem;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
          margin-right: 1.25rem;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
        }
      }
    }
    .info_freight {
      display: flex;
      justify-content: flex-end;
      font-size: 0.875rem;
      color: #333333;
      margin-bottom: 1.125rem;
      div:first-child {
        margin-right: 6.18rem;
        span:first-child {
          margin-right: 0.625rem;
        }
        span:nth-child(2) {
          color: #d2d1d1;
        }
      }
      div:nth-child(2) {
        span:first-child {
          font-size: 0.5625rem;
        }
        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
    .info_refund {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 0.75rem;
      div {
        width: 4.125rem;
        height: 1.75rem;
        border-radius: 1.875rem;
        border: 1px solid #e8e8e8;
        font-size: 0.75rem;
        color: #999;
        text-align: center;
        line-height: 1.75rem;
      }
    }
    .info_price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 1rem;
      color: #333333;
      div:first-child {
        font-weight: 700;
      }
      div:nth-child(2) {
        color: #fe4a4a;
        span:first-child {
          font-size: 0.5625rem;
        }
        span:nth-child(2) {
          font-size: 1rem;
          font-weight: 700;
        }
      }
    }
    .info_num {
      position: absolute;
      right: 0.9375rem;
      top: 4.8125rem;
    }
  }
  .evaluate_cont {
    padding: 0.9375rem;
    background: #fff;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rate {
        line-height: 1;
        display: flex;
        align-items: center;
        span {
          margin-right: 1.6875rem;
          color: #333333;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    .release_text {
      padding: 0;
      margin: 0.75rem 0;
    }
  }
}
.van-cell {
  &::after {
    border: none;
  }
}
.tj_btn {
  width: 17.5rem;
  height: 2.625rem;
  background: linear-gradient(to right, #000000, #000000);
  color: #ffffff;
  font-size: 0.9375rem;
  text-align: center;
  line-height: 2.625rem;
  margin: 4.25rem auto;
  border-radius: 1.875rem;
}
.c_999999 {
  color: #999;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
