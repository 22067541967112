<!--
 * @Descripttion: 说明
 * @Author: liuzhixiang
 * @Date: 2021-07-14 09:27:27
 * @LastEditors: liuzhixiang
 * @LastEditTime: 2021-07-14 09:56:27
-->
<template>
  <div class="navBar">
    <van-nav-bar :title="title">
      <template #left v-if="left>0">
        <van-icon name="arrow-left" size="18" color="#000" @click="goBack" />
      </template>
      <template #right v-if="right>0">
        <div @click="handle">{{rightText}}</div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>
    //     <navBar title="购物车" left="0" right="1" rightText="管理" @handleFather="handleFather"></navBar>
export default {
    // title文字 left、right大于0显示，小于等于0隐藏 rightText右边文字
  props: ["title","left","right","rightText"],
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
      goBack(){
        this.$router.go(-1);
      },
      handle(){
        this.$emit('handleFather');
      }
  },
};
</script>

<style lang="less" scoped>
.navBar {
}
</style>
